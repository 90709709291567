export enum QuizUserStatus {
  PENDING = 'pending', // The user started the registration (in /quiz), but did not finish.
  QuizCompleted = 'quiz-completed', // The user completed the quiz, but has not yet started the registration.
  ENROLLED = 'enrolled', // The user completed the quiz, finished the registration, and is enrolled in the program (SGE).
  MIGRATED = 'migrated', // Indicates that the user completed the registration process and their data was successfully transferred to the main user collection.
  NonParticipant = 'non-participant' // The user belongs to a state/DR that does not participate in the program.
}

export type QuizUser = {
  id?: string;
  name: string;
  email: string;
  phone: string;
  cpf: string;
  state: string;
  city: string;
  registrationQuiz: number | null; //  1 or 2
  status: QuizUserStatus;
  isDrParticipant: boolean;
  quizCompletionDate: Date | null;
  isReminderSent: boolean;
  reminderDate: Date | null;
  migrationDate: Date | null;
  receiveEmails?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};
