import React, { ReactElement, useEffect, useState } from 'react';
import MuiTooltip, {
  TooltipProps,
  tooltipClasses
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

type TooltipComponentProps = {
  readonly children: ReactElement;
  readonly title: string;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow={true} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#707070',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)',
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Poppins',
    maxWidth: 224,
    padding: 12,
    borderRadius: 8
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  }
}));

function Tooltip({ children, title }: TooltipComponentProps): JSX.Element {
  const [placement, setPlacement] = useState<'bottom' | 'right-end'>(
    'right-end'
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setPlacement('bottom');
      } else {
        setPlacement('right-end');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <LightTooltip title={title} placement={placement}>
      {children}
    </LightTooltip>
  );
}

export default Tooltip;
