import React, { CSSProperties, ChangeEvent, forwardRef } from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel
} from '@devstart/react-bootstrap';
import './select.css';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';

type SelectOptionProps = {
  value: string;
  option: string;
};

type StyleTypeProps = 'type-1' | 'type-2';

type SelectProps = {
  label?: string;
  style?: CSSProperties;
  options: SelectOptionProps[];
  error?: string | null;
  placeholder?: string;
  value?: string;
  selectStyleType?: StyleTypeProps;
  onChangeSelect?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & React.HTMLAttributes<HTMLSelectElement> & { [key: string]: unknown };

const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  {
    label,
    style,
    options,
    error,
    placeholder,
    value,
    selectStyleType = 'type-1',
    onChangeSelect,
    ...rest
  },
  ref
): JSX.Element {
  const isPlaceholderSelected = value === '';

  return (
    <FormGroup
      className={`select-container input-container input-select-container ${
        isPlaceholderSelected ? 'placeholder-selected' : ''
      }`}
      style={style}
    >
      <ControlLabel>{label}</ControlLabel>

      <div className='select-input'>
        <FormControl
          componentClass='select'
          className={`${selectStyleType === 'type-2' ? 'select-type-2' : ''}`}
          placeholder={placeholder}
          onChange={onChangeSelect}
          value={value}
          ref={ref}
          {...rest}
        >
          {placeholder && (
            <option value='' disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ option, value }) => (
            <option key={value} value={value}>
              {option}
            </option>
          ))}
        </FormControl>

        <div className='icon-area open-select'>
          <ChevronDown2 />
        </div>
      </div>

      {error && <span className='error-message'>{error}</span>}
    </FormGroup>
  );
});

Select.displayName = 'Select';

export default Select;
