import QuizLayout from '@components/layouts/quiz-layout';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import Input from '@ui/Input';
import { Controller, useForm } from 'react-hook-form';
import {
  isCPFValid,
  isEmail,
  isValidPhoneNumber
} from '@utils/data-complement/validate-inputs';
import Select from '@ui/Select';
import Tooltip from '@ui/Tooltip';
import { connect } from 'react-redux';
import { registerQuizUser } from '@redux/actions';
import { createSelector } from 'reselect';
import { isLoadingRegisterQuizUserSelector } from '@redux/selectors';
import { add } from 'date-fns';
import { QuizUser, QuizUserStatus } from './types';
import { statesAndCities } from './state-and-cities';

type SelectOption = {
  value: string;
  option: string;
};

type FormData = {
  name: string;
  phone: string;
  email: string;
  cpf: string;
  state: string;
  city: string;
};

type QuizPageProps = {
  readonly isLoadingRegisterQuizUser: boolean;
  readonly registerQuizUser: (data: QuizUser) => void;
};

const Container = styled('div')`
  background: #25132d;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const LeftCircle = styled('div')`
  width: 494px;
  height: 494px;
  position: absolute;
  left: -271px;
  top: -51px;
  border-radius: 494px;
  background: rgba(119, 53, 149, 0.5);
  filter: blur(82.75px);
  pointer-events: none;
`;

const RightCircle = styled('div')`
  width: 484px;
  height: 484px;
  position: absolute;
  right: -353px;
  bottom: -163px;
  border-radius: 484px;
  background: rgba(242, 0, 130, 0.5);
  filter: blur(82.75px);
  pointer-events: none;
`;

const MainContent = styled('div')`
  max-width: 960px;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  padding: 2rem;

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
`;

const TextContent = styled('div')`
  max-width: 469px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;

  @media (max-width: 960px) {
    gap: 1rem;
    padding: 0rem;
    max-width: 427px;
  }
`;

const MainTitle = styled('h1')`
  color: var(--System-White, #fff);
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1.04px;
  margin: 0;

  @media (max-width: 960px) {
    font-size: 24px;
    line-height: 120%;
  }
`;

const Highlight = styled('span')`
  color: var(--Pallets-Primary-800, #f081b8);
`;

const Subtitle = styled('p')`
  color: var(--System-White, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 150%;
  }
`;

const MainForm = styled('form')`
  max-width: 427px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TermsOfUse = styled('p')`
  color: var(--Transparence-White-80, rgba(255, 255, 255, 0.8));
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`;

const initialFormValues = {
  name: '',
  phone: '',
  email: '',
  cpf: '',
  state: '',
  city: ''
};

const states: SelectOption[] = statesAndCities.estados.map(state => ({
  value: state.sigla,
  option: state.nome
}));

const mapStateToProps = createSelector(
  isLoadingRegisterQuizUserSelector,
  (isLoadingRegisterQuizUser: boolean) => ({
    isLoadingRegisterQuizUser
  })
);

const mapDispatchToProps = {
  registerQuizUser
};

function QuizPage({
  isLoadingRegisterQuizUser,
  registerQuizUser
}: QuizPageProps): JSX.Element {
  const [cities, setCities] = useState<SelectOption[]>([]);
  const {
    register,
    setValue,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormData>({ defaultValues: initialFormValues });

  const handleSetCities = useCallback(
    (stateAbbreviation: string) => {
      const cities =
        statesAndCities.estados.find(
          state => state.sigla === stateAbbreviation.toLocaleUpperCase()
        )?.cidades ?? [];
      setCities(cities.map(city => ({ value: city, option: city })));
      setValue('city', '');
    },
    [setValue]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');
    if (state) {
      setValue('state', state.toUpperCase());
      handleSetCities(state);
    }
  }, [setValue, handleSetCities]);

  const onSubmit = (data: FormData) => {
    const now = new Date();
    const reminderDate = add(now, { hours: 24 });
    const participantDrs = ['PI', 'PR'];

    const newQuizUser: QuizUser = {
      ...data,
      status: QuizUserStatus.PENDING,
      registrationQuiz: null,
      isDrParticipant: participantDrs.includes(data.state),
      quizCompletionDate: null,
      isReminderSent: false,
      reminderDate,
      migrationDate: null
    };

    registerQuizUser(newQuizUser);
  };

  return (
    <QuizLayout>
      <Container>
        <LeftCircle />
        <RightCircle />

        <MainContent>
          <TextContent>
            <MainTitle>
              Faça o teste e inicie sua{' '}
              <Highlight>Jornada DEV SENAI!</Highlight>
            </MainTitle>
            <Subtitle>
              Aprenda programação front-end gratuitamente, com certificados e
              qualidade de ensino SENAI.
            </Subtitle>
          </TextContent>

          <MainForm onSubmit={handleSubmit(onSubmit)}>
            <Input
              type='text'
              placeholder='Nome'
              hasError={errors.name?.message}
              messageError={errors.name?.message}
              error={errors.name?.message}
              inputStyleType='type-2'
              {...register('name', { required: 'Campo é obrigatório' })}
            />

            <Input
              type='text'
              placeholder='Telefone'
              hasError={errors.phone?.message}
              messageError={errors.phone?.message}
              inputStyleType='type-2'
              hasMask
              maskValue='(99) 99999-9999'
              {...register('phone', {
                required: 'Campo é obrigatorio',
                validate: value => {
                  if (value && !isValidPhoneNumber(value)) {
                    return 'Telefone inválido';
                  }
                }
              })}
            />

            <Tooltip title='O e-mail que você cadastrar aqui, será o mesmo para as próximas etapas da Jornada Dev SENAI.'>
              <Input
                type='text'
                placeholder='E-mail'
                hasError={errors.email?.message}
                messageError={errors.email?.message}
                inputStyleType='type-2'
                {...register('email', {
                  required: 'Campo é obrigatorio',
                  validate: value => {
                    if (value && !isEmail(value)) {
                      return 'E-mail inválido';
                    }
                  }
                })}
              />
            </Tooltip>

            <Input
              type='text'
              placeholder='CPF'
              hasError={errors.cpf?.message}
              messageError={errors.cpf?.message}
              inputStyleType='type-2'
              hasMask
              maskValue='999.999.999-99'
              {...register('cpf', {
                required: 'Campo é obrigatorio',
                validate: value => {
                  if (value && !isCPFValid(value)) {
                    return 'CPF inválido';
                  }
                }
              })}
            />

            <Controller
              control={control}
              name='state'
              rules={{
                required: {
                  value: true,
                  message: 'Campo obrigatório'
                }
              }}
              defaultValue=''
              render={({ field: { value, onChange } }) => (
                <Select
                  placeholder='Estado'
                  options={states}
                  error={errors.state?.message}
                  selectStyleType='type-2'
                  value={value}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    onChange(e);
                    const stateAbbreviation = e.target.value;
                    handleSetCities(stateAbbreviation);
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name='city'
              rules={{
                required: {
                  value: true,
                  message: 'Campo obrigatório'
                }
              }}
              defaultValue=''
              render={({ field: { value, onChange } }) => (
                <Select
                  placeholder='Cidade'
                  options={cities}
                  error={errors.city?.message}
                  selectStyleType='type-2'
                  value={value}
                  onChange={onChange}
                  disabled={cities.length === 0}
                />
              )}
            />

            <Button
              buttonType={ButtonTypes.Primary}
              style={{
                marginTop: 12,
                marginBottom: 12,
                width: '100%'
              }}
              type='submit'
              isLoading={isLoadingRegisterQuizUser}
            >
              COMEÇAR O TESTE
            </Button>

            <TermsOfUse>
              Ao começar o teste, você concorda com os Termos de Uso e a
              Política de Privacidade do DEVstart.
            </TermsOfUse>
          </MainForm>
        </MainContent>
      </Container>
    </QuizLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
